import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import { compose } from 'ramda';
import { Dictionary } from 'shared';
import { fetchPurchases, getIsPurchasesEmpty } from 'store/modules/purchases';
import { SkeletonLoaderProfileHeader } from 'ui-kit/atoms/SkeletonLoader';
import {
    RULE_MARKET,
    RULE_MEDIA_SECTION,
    RULE_SUBSCRIPTIONS,
} from 'utils/allowFeature';

import { Settings } from '@s7/ui-kit/icons';

import ProfileHeader from '../../components/ProfileHeader';
import Loadable from '../../decorators/Loadable';
import {
    isError as isErrorLanguages,
    isLoadingLanguagesGlobal,
} from '../../store/modules/languages';
import {
    getBusinessUpgrade,
    getFlights,
    getLoyalLevel,
    getMembershipID,
    getMiles,
    getMilesStatus,
    isInitialized,
} from '../../store/modules/loyalty';
import {
    deleteAvatar,
    getAvatarError,
    getAvatarPreviewLink,
    getCustomerValue,
    getIsAvatarDeleting,
    getIsAvatarSaving,
    getPreparedPreferences,
    getProfileNames,
    resetAvatarError,
    saveAvatar,
} from '../../store/modules/profile';
import { changePriorityCardOpen } from '../../store/modules/shared';
import { dictKeys, getTestIdGetter } from '../../utils/testIds';

const getTestId = getTestIdGetter(dictKeys.HEADER);

const tabs = [
    {
        href: '/',
        id: 'tab-profile',
        title: <Dictionary text="com.header.tabs.profile" />,
    },
    {
        href: '/miles',
        id: 'tab-miles',
        title: <Dictionary text="com.header.tabs.miles" />,
    },
    {
        href: '/travels',
        id: 'tab-trips',
        title: <Dictionary text="com.header.tabs.travels" />,
    },
    ...(RULE_SUBSCRIPTIONS
        ? [
              {
                  href: '/subscriptions',
                  id: 'tab-subscriptions',
                  title: <Dictionary text="com.header.tabs.subscriptions" />,
              },
          ]
        : []),
    ...(RULE_MEDIA_SECTION
        ? [
              {
                  href: '/media',
                  id: 'tab-media',
                  title: <Dictionary text="com.header.tabs.media" />,
              },
          ]
        : []),
    {
        href: '/statistics',
        id: 'tab-statistics',
        title: <Dictionary text="com.header.tabs.statistics" />,
    },
    {
        href: '/offers',
        id: 'tab-offers',
        title: <Dictionary text="com.header.tabs.offers" />,
    },
    ...(RULE_MARKET
        ? [
              {
                  href: '/shop',
                  id: 'tab-shop',
                  title: <Dictionary text="com.header.tabs.shop" />,
              },
          ]
        : []),
    {
        alignRight: true,
        href: '/settings',
        id: 'tab-settings',
        startIcon: Settings,
        title: <Dictionary text="com.header.settings" />,
    },
];

const ProfileHeaderContainer = ({ tab }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchPurchases());
    }, [dispatch]);

    /* Avatar */
    const imgURL = useSelector(getAvatarPreviewLink);
    const isAvatarSaving = useSelector(getIsAvatarSaving);
    const isAvatarDeleting = useSelector(getIsAvatarDeleting);
    const avatarError = useSelector(getAvatarError);
    const handleSaveAvatar = (...args) => dispatch(saveAvatar(...args));
    const handleDeleteAvatar = (...args) => dispatch(deleteAvatar(...args));
    const handleResetAvatarError = (...args) =>
        dispatch(resetAvatarError(...args));

    /* Loyalty */
    const miles = useSelector(getMiles);
    const milesStatus = useSelector(getMilesStatus);
    const milesBonus = useSelector(getMiles);
    const flights = useSelector(getFlights);
    const businessUpgrade = useSelector(getBusinessUpgrade);

    const membershipID = useSelector(getMembershipID);
    const customerValue = useSelector(getCustomerValue);
    const loyalLevel = useSelector(getLoyalLevel);
    const isLoyaltyInitialized = useSelector(isInitialized);

    /* Profile */
    const { name, surname, isEnName } = useSelector(getProfileNames);
    const preferences = useSelector(getPreparedPreferences);

    /* Purchases */
    const isPurchasesEmpty = useSelector(getIsPurchasesEmpty);

    const handleOpenPriorityCard = useCallback(() => {
        dispatch(changePriorityCardOpen(true));
    }, [dispatch]);

    const currentTabs = useMemo(
        () =>
            !isPurchasesEmpty
                ? tabs
                : tabs.filter(({ id }) => id !== 'tab-purchases'),
        [isPurchasesEmpty],
    );

    return (
        <ProfileHeader
            {...{
                avatarError,
                businessUpgrade,
                customerValue,
                deleteAvatar: handleDeleteAvatar,
                flights,
                getTestId,
                imgURL,
                isAvatarDeleting,
                isAvatarSaving,
                isDoctor: preferences.doctor,
                isEnName,
                isLoyaltyInitialized,
                loyalLevel,
                membershipID,
                miles,
                milesBonus,
                milesStatus,
                name,
                onOpenPriorityCard: handleOpenPriorityCard,
                resetAvatarError: handleResetAvatarError,
                saveAvatar: handleSaveAvatar,
                surname,
                tab,
                tabs: currentTabs,
            }}
        />
    );
};

ProfileHeaderContainer.propTypes = {
    tab: string,
};

export default compose(
    Loadable({
        isError: state =>
            !(
                !state.profile.isError &&
                !state.countries.isError &&
                !isErrorLanguages(state)
            ),
        isLoading: state =>
            !(state.profile.isInitialized && !isLoadingLanguagesGlobal(state)),
        Loader: <SkeletonLoaderProfileHeader />,
    }),
    React.memo,
)(ProfileHeaderContainer);
