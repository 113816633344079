import React, { useEffect, useRef, useState } from 'react';
import { useAnalyticsContext } from 'contexts';
import { useStyleVariantContext } from 'contexts/styleVariantContext';
import { useWindowSize } from 'hooks';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { arrayOf, bool, elementType, node, shape, string } from 'prop-types';
import { keyCodes } from 'utils/keyCodes';
import { dictKeys, getTestIdGetter } from 'utils/testIds';

import { Text } from '@s7/ui-kit';

import { RippleElement } from './components';
import cx from './MainMenu.sss';

const generateTestId = getTestIdGetter(dictKeys.TABS);

const getTestID = (id, isSelected) => {
    const ids = {
        'tab-miles': 'miles',
        'tab-offers': 'offers',
        'tab-profile': 'profile',
        'tab-purchases': 'purchases',
        'tab-settings': 'settings',
        'tab-statistics': 'statistics',
        'tab-trips': 'trips',
    };

    return generateTestId(ids[id], { isSelected });
};

const sendGA = (id, sendEvent) => {
    const action = {
        'tab-miles': 'miles',
        'tab-offers': 'bonuses',
        'tab-profile': 'profile_info',
        'tab-settings': 'settings',
        'tab-statistics': 'statistics',
        'tab-subscriptions': 's7_boost',
        'tab-trips': 'travel_history',
    }[id];

    sendEvent({
        event: 'view_screen',
        step: action,
        ux_ui: 'avatar_menu',
    });
};

export const MainMenu = ({ links, isPublic }) => {
    const { asPath, pathname } = useRouter();
    const { variant } = useStyleVariantContext();
    const windowSize = useWindowSize();
    const { sendEvent } = useAnalyticsContext();
    const [markerPosition, setMarkerPosition] = useState({
        left: 0,
        width: 0,
    });
    const currentActiveItem = useRef();

    const indicatorPosition = ({ offsetWidth, offsetLeft }) => {
        setMarkerPosition({
            left: offsetLeft,
            width: offsetWidth,
        });
    };

    const handleKeydown = id => e => {
        if (e.keyCode === keyCodes.Enter) {
            sendGA(id, sendEvent);
            indicatorPosition(e.target);
        }
    };

    const handleClick = id => e => {
        sendGA(id, sendEvent);
        indicatorPosition(e.target);
    };

    useEffect(() => {
        if (currentActiveItem.current && pathname) {
            indicatorPosition(currentActiveItem.current);
        }
    }, [windowSize, pathname]);

    return (
        <nav
            className={cx('root', {
                media: variant === 'media',
            })}
            aria-label="S7 Profile menu"
        >
            <ul className={cx('menu', { public: isPublic })}>
                {links.map(
                    (
                        { startIcon, alignRight, id, href, disabled, title },
                        idx,
                    ) => {
                        const Icon = startIcon;
                        const isCurrentPage =
                            asPath === href ||
                            pathname === href ||
                            (pathname.includes('public') && idx === 0);
                        const isSubPage =
                            href !== '/' &&
                            (asPath.indexOf(href) === 0 ||
                                pathname.indexOf(href) === 0);
                        const isActive = isCurrentPage || isSubPage;

                        return (
                            <Text
                                key={id}
                                classes={{
                                    root: cx('menu_item', {
                                        align_right: alignRight,
                                    }),
                                }}
                                as="li"
                                role="none"
                            >
                                <Link href={href} passHref>
                                    <RippleElement
                                        ref={
                                            isActive
                                                ? currentActiveItem
                                                : undefined
                                        }
                                        aria-current={
                                            isActive ? 'page' : undefined
                                        }
                                        className={cx('menu_link', {
                                            disabled,
                                            media: variant === 'media',
                                        })}
                                        as="a"
                                        id={id}
                                        tabIndex={0}
                                        onClick={
                                            disabled
                                                ? undefined
                                                : handleClick(id)
                                        }
                                        onKeyDown={
                                            disabled
                                                ? undefined
                                                : handleKeydown(id)
                                        }
                                        {...getTestID(id, isActive)}
                                    >
                                        <span className={cx('menu_icon')}>
                                            {Icon && (
                                                <Icon height={16} width={16} />
                                            )}
                                        </span>
                                        {title}
                                    </RippleElement>
                                </Link>
                            </Text>
                        );
                    },
                )}
                <li
                    className={cx('marker', {
                        media: variant === 'media',
                    })}
                    style={markerPosition}
                />
            </ul>
        </nav>
    );
};

MainMenu.propTypes = {
    isPublic: bool,
    links: arrayOf(
        shape({
            alignRight: bool,
            disabled: bool,
            href: string,
            id: string,
            startIcon: elementType,
            title: node,
        }),
    ),
};
