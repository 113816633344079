import {
    AnalyticsManager,
    createPluginGA,
    createPluginYM,
} from '@s7/analytics';

import { GA_ID, YA_ID } from '../config';

const isClient = typeof window === 'object';

const createAppFlowContext = () => {
    if (!isClient) {
        return null;
    }
    const mainPaths = ['/', '/ru/', '/en/', '/zh/', '/de/', '/it/', '/es/'];

    if (mainPaths.indexOf(window.location.pathname) > -1) {
        return 'main';
    }
    return null;
};

const flow = createAppFlowContext();

const createAppUserIdContext = () => {
    let profileId: string;

    function set(id: string) {
        profileId = id;
    }

    function get() {
        return profileId;
    }

    return {
        get,
        set,
    };
};

export const userId = createAppUserIdContext();

export const analytics = AnalyticsManager({
    transform: (eventName, payload) => ({
        application: 'myprofile',
        event_name: eventName,
        ...(flow && { flow }),
        ...payload,
    }),
    plugins: [
        createPluginGA({
            initialize: { containerId: GA_ID },
        }),
        createPluginYM({
            initialize: () => ({
                counterId: YA_ID,
                instanceKey: 'ym',
                status: () => true,
            }),
            transform: (eventName, payload) => ({
                user_id: userId.get(),
                ...payload,
            }),
        }),
    ],
});
