import React, { MouseEventHandler, useMemo } from 'react';
import { LoyaltyLevel } from 'src/shared/types/loyalty-level';
import { LabelsList } from 'src/widgets/labels-list';
import { normalizeName, noun, numberWithSpaces } from 'utils/format';

import { Text, Title } from '@s7/ui-kit';
import { InfoFiled, Milles } from '@s7/ui-kit/icons';

import cx from './UserInfo.sss';

import {
    UNITS_BONUS_MILES,
    UNITS_BUSINESS_UPGRADE,
    UNITS_MILES,
    UNITS_STATUS_FLIGHTS,
    UNITS_STATUS_MILES,
} from '../../consts/units';
import { useBreakpoint } from '../../hooks';
import { Dictionary } from '../../shared';
import { SkeletonLoaderUserInfo } from '../../ui-kit/atoms/SkeletonLoader';
import Avatar from '../Avatar';
import Link from '../Link';

interface UserInfoProps {
    avatarError: string;
    businessUpgrade: number;
    customerValue: string;
    deleteAvatar: () => Record<string, unknown>;
    getTestId: (a: string) => Record<string, unknown>;
    flights: number;
    imgURL: string;
    isAvatarDeleting: boolean;
    isAvatarSaving: boolean;
    isDoctor: boolean;
    isEnName: boolean;
    isLoyaltyInitialized: boolean;
    isPublic: boolean;
    loyalLevel: LoyaltyLevel;
    loyalLink: string;
    membershipID: string;
    miles: number;
    milesBonus: number;
    milesStatus: number;
    name: string;
    onOpenPriorityCard: MouseEventHandler<HTMLButtonElement>;
    resetAvatarError: () => Record<string, unknown>;
    saveAvatar: () => Record<string, unknown>;
    surname: string;
}

const UserInfo = ({
    avatarError,
    businessUpgrade,
    customerValue,
    deleteAvatar,
    flights,
    getTestId,
    imgURL,
    isAvatarDeleting,
    isAvatarSaving,
    isDoctor,
    isLoyaltyInitialized,
    isPublic,
    loyalLevel,
    loyalLink,
    membershipID,
    miles,
    milesBonus,
    milesStatus,
    name,
    onOpenPriorityCard,
    resetAvatarError,
    saveAvatar,
    surname,
}: UserInfoProps): JSX.Element => {
    const isMobile = useBreakpoint().only('xs');

    const isFullUser = customerValue === 'FULL';

    interface RenderInfoColProps {
        count: number | null;
        id: string;
        units: JSX.Element[];
    }

    const renderInfoCol = ({
        count,
        id,
        units,
    }: RenderInfoColProps): JSX.Element => (
        <div key={id} className={cx('col')}>
            <div className={cx('count')}>
                <Title color={!isFullUser ? 'N80' : undefined} level={4}>
                    {numberWithSpaces(count)}
                </Title>
            </div>

            <Text
                // eslint-disable-next-line no-nested-ternary
                color={!isFullUser ? 'N80' : isMobile ? 'N900' : 'N100'}
                size={isMobile ? undefined : 's'}
            >
                {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    noun(count, units)
                }
            </Text>
        </div>
    );

    const info = useMemo(
        () =>
            [
                {
                    count: milesBonus,
                    id: 'milesBonus',
                    units: UNITS_BONUS_MILES,
                },
                {
                    count: milesStatus,
                    id: 'milesStatus',
                    units: UNITS_STATUS_MILES,
                },
                {
                    count: flights,
                    id: 'flights',
                    units: UNITS_STATUS_FLIGHTS,
                },
                {
                    count: businessUpgrade || null,
                    id: 'businessUpgrade',
                    units: UNITS_BUSINESS_UPGRADE,
                },
            ].filter(infoObject => infoObject.count !== null),
        [milesBonus, milesStatus, flights, businessUpgrade],
    );

    const loyaltyDataLayout = useMemo(() => {
        if (!membershipID) {
            return null;
        }

        const levelLayout = (
            <>
                <span className={cx('link')}>
                    {numberWithSpaces(membershipID)} / {loyalLevel}
                </span>
                <InfoFiled />
            </>
        );

        return onOpenPriorityCard ? (
            <button
                className={cx('loyalty')}
                type="button"
                onClick={onOpenPriorityCard}
            >
                {levelLayout}
            </button>
        ) : (
            <a
                className={cx('loyalty')}
                href={loyalLink}
                rel="noopener noreferrer"
                target="_blank"
            >
                {levelLayout}
            </a>
        );
    }, [loyalLevel, loyalLink, membershipID, onOpenPriorityCard]);

    const renderUserInfo = (): null | JSX.Element => {
        if (isPublic) {
            return null;
        }

        return !isLoyaltyInitialized && isFullUser ? (
            <div className={cx('info-loader')}>
                <SkeletonLoaderUserInfo />
            </div>
        ) : (
            <>
                {membershipID ? (
                    loyaltyDataLayout
                ) : (
                    <Link
                        className={cx('loyalty')}
                        href="/registration/loyalty"
                    >
                        <Milles />
                        <span className={cx('link', 'collecting')}>
                            <Dictionary text="com.miles.collecting" />
                        </span>
                    </Link>
                )}

                <div className={cx('row')}>
                    <LabelsList />
                    {isMobile || info.map(renderInfoCol)}
                    {isMobile &&
                        renderInfoCol({
                            count: miles,
                            id: 'miles',
                            units: UNITS_MILES,
                        })}
                </div>
            </>
        );
    };

    const normalizedName =
        !isPublic && name
            ? normalizeName(name, {
                  withSpaces: true,
              })
            : name;

    const nameText =
        name && surname
            ? `${normalizedName} ${surname[0]}.`
            : normalizedName || '—';

    return (
        <div className={cx('root', { isPublic })}>
            <div className={cx('avatar')}>
                <Avatar
                    {...{
                        avatarError,
                        deleteAvatar,
                        imgURL,
                        isAvatarDeleting,
                        isAvatarSaving,
                        isDoctor,
                        isPublic,
                        name: name && surname ? `${name[0]}${surname[0]}` : '',
                        resetAvatarError,
                        saveAvatar,
                    }}
                />
            </div>

            <div className={cx('info')}>
                <div className={cx('name')}>
                    <Title level={3} {...getTestId('userInfoName')}>
                        {nameText}
                    </Title>
                </div>
                {renderUserInfo()}
            </div>
        </div>
    );
};

export default React.memo(UserInfo);
